import { DateNormalizer } from '../date_utils/normalizer'
import { DateTime } from 'luxon'

/**
 * Farthest -> Nearest
 * Max is the farthest date, Min is the nearest date
 * max: 30, min: 0 means 30 days ago to today
 */

export default class RelativePeriod {
  constructor(options) {
    this.lookbackMin = options.lookbackMin
    this.lookbackMax = options.lookbackMax
    this.anchor = this.#normalize(options.anchorDate) || this.today
  }

  get summaryData() {
    return {
      dayRange: this.dayRange,
      toAbsolute: this.toAbsolute
    }
  }

  get dayRange() {
    return [this.lookbackMax, this.lookbackMin]
  }

  set dayRange(days) {
    this.lookbackMax = days[0]
    this.lookbackMin = days[1]
  }

  get daysProvided() {
    return Boolean(this.lookbackMin) || Boolean(this.lookbackMax)
  }

  get fullDayRangeProvided() {
    return this.validLookbackMin && this.validLookbackMax
      && this.lookbackMin < this.lookbackMax
  }

  get validLookbackMin() {
    return this.lookbackMin >= 0
  }

  get validLookbackMax() {
    return this.lookbackMax >= 0
  }

  get toAbsolute() {
    return this.dayRange.map(daysAgo => this.anchor.minus({ days: daysAgo }))
  }

  get isFollowable() {
    return this.fullDayRangeProvided
  }

  get today() {
    return DateTime.local()
  }

  #normalize(date) {
    if (!Boolean(date)) { return null }

    return DateNormalizer.normalize(date)
  }
}

export { RelativePeriod }
