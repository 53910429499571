/*
  Presets for a single date field
*/

import { Controller } from "@hotwired/stimulus"
import { DateNormalizer } from "../date_utils/normalizer"

// Connects to data-controller="date-preset"
export default class extends Controller {
  static values = {
    date: String
  }

  static outlets = [ 'date-field', 'inline-picker' ]

  connect() {
    this.periodData = app.periodData
    this.date
  }

  /**
   * Handle when the user clicks a preset
   * @param {event} event
   */
  setPreset(event) {
    event.preventDefault()

    this.date = this.startDateFor(event.params.preset)

    if (this.hasDateFieldOutlet) {
      this.setField()
    } else {
      this.setPicker()
    }
  }

  setField() {
    this.dateFieldOutlet.setByDateTime(this.date)
  }

  startDateFor(preset) {
    return this.periodData.build(preset, this.fromDate).dateStart
  }

  get fromDate() {
    if (this.hasDateValue) {
      return new DateNormalizer(this.dateValue).toJSDate()
    } else {
      return new Date()
    }
  }
}
