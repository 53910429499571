import { DateNormalizer } from '../date_utils/normalizer'
import { DateTime } from 'luxon'

export default class AbsolutePeriod {
  constructor(options) {
    this.dateMin = this.normalize(options.dateMin)
    this.dateMax = this.normalize(options.dateMax)

    this.prefix = options.prefix
    this.leader = options.leader
    this.anchor = this.normalize(options.anchorDate) || this.today
  }

  get summaryData() {
    return {
      dateRange: this.dateRange,
      toRelative: this.toRelative,
      prefix: this.prefix,
    }
  }

  get dateRange() {
    return [
      this.dateMin,
      this.dateMax
    ]
  }

  set dateRange(dates) {
    this.dateMin = dates[0]
    this.dateMax = dates[1]
  }

  get dateRangeString() {
    return this.dateRange.map(date => date.toISODate())
  }

  get isValid() {
    return this.datesProvided
  }

  get datesProvided() {
    return Boolean(this.dateMin) || Boolean(this.dateMax)
  }

  get fullDateRangeProvided() {
    return Boolean(this.dateMin) && Boolean(this.dateMax)
  }

  get toRelative() {
    return this.dateRange.map(date => this.#diffInDays(this.anchor, date))
  }

  get today() {
    return DateTime.local()
  }

  #diffInDays(later, earlier) {
    return Math.trunc(later.diff(earlier, 'days').as('days'))
  }

  normalize(date) {
    if (!Boolean(date)) { return null }

    return DateNormalizer.normalize(date)
  }
}

export { AbsolutePeriod }
