import { DateNormalizer } from '../date_utils/normalizer'
import RelativeSummarizer from '../date_utils/relative_summarizer'
// import { DayStrategy } from './day_strategy'
import { DateTime } from 'luxon'

export default class DynamicPeriod {
  constructor(options) {
    this.anchorDate = this.#normalize(options.anchorDate)
    this.periodName = options.periodName
    this.leader = options.leader
  }

  get summaryData() {
    return {
      periodName: this.periodName,
      toAbsolute: this.toAbsolute,
      toAbsoluteStr: this.toAbsoluteStr,
      toRelative: this.toRelative,
      toRelativeStr: this.toRelativeStr,
      followable: this.isFollowable,
      canFollow: this.canFollow,
    }
  }

  get dateRange() {
    if (this.canProduceDynamicRange) {
      return this.strategyForPeriod?.dateTimeRange
    }
  }

  get strategyForPeriod() {
    switch (this.effectivePeriod) {
      case 'prior_period':
        return this.priorPeriodStrategy
      case 'prior_year':
        return this.priorYearStrategy
      default:
        return this.standardStrategy
    }
  }

  get priorPeriodStrategy() {
    return this.leader?.priorPeriod
  }

  get priorYearStrategy() {
    return this.leader?.priorYear
  }

  get standardStrategy() {
    return app.periodData.build(this.effectivePeriod, this.anchorDate)
  }

  // get dayStrategy() {
  //   let ds = new DayStrategy()
  //   // ds.fromDate = this.unifiedDateRange[1]
  //   ds.dateEnd = this.unifiedDateRange[1]
  //   ds.dateStart = this.unifiedDateRange[0]
  //   ds.customRange = true
  //   ds.period = 'custom'

  //   return ds
  // }

  get priorPeriod() {
    return this.strategyForPeriod.compare('period')
  }

  get priorYear() {
    return this.strategyForPeriod.compare('year')
  }

  get periodIsValid() {
    return this.periodIsFollower || this.periodIsKnown
  }

  // Follows the shape of other period types
  get datesProvided() { return this.periodIsValid }

  get periodIsFollower() {
    return ['prior_period', 'prior_year'].includes(this.periodName)
  }

  get periodIsKnown() {
    return Boolean(app.periodData.find(this.periodName))
  }

  /**
   * When the period is following, the leader must be followable. Otherwise,
   * we must have a good period. Trying to produce a dynamic range without some
   * minimum data will cause errors.
   */
  get canProduceDynamicRange() {
    if (this.periodIsFollower) {
      return Boolean(this.canFollow)
    } else {
      return this.periodIsKnown
    }
  }

  // if prior_period or prior_year then we need the leader, otherwise the period
  // should be known at least.
  get canConvertDynamic() {
    if (this.periodIsFollower) {
      return Boolean(this.leader?.isFollowable)
    } else {
      return this.periodIsKnown
    }
  }

  get canFollow() {
    return this.leader?.isFollowable && this.canConvertDynamic
  }

  get isFollowable() {
    return this.periodIsValid && !this.periodIsFollower
  }

  get effectivePeriod() {
    return this.periodIsValid ? this.periodName : 'custom'
  }

  get toAbsolute() {
    // if (!this.canConvertDynamic) { return [] }
    // if (this.isDynamic) { return this.dateRange }

    if (this.canConvertDynamic) {
      return this.dateRange
    } else {
      return []
    }
  }

  get toAbsoluteStr() {
    if (this.canConvertDynamic) {
      return this.toAbsolute
        .map(dt => dt.toLocaleString(DateTime.DATE_SHORT))
        .join('–')
    } else {
      return ''
    }
  }

  get toRelative() {
    if (this.isDynamic && this.canConvertDynamic) {
      return this.dateRange.map(date => this.diffInDays(this.today, date))
    } else {
      return []
    }
  }

  get toRelativeStr() {
    if (!this.isDynamic || !this.canConvertDynamic) { return "" }

    let summarizer = new RelativeSummarizer({
      min: this.toRelative[1],
      max: this.toRelative[0]
    })

    return summarizer.summary
  }

  #normalize(date) {
    if (!Boolean(date)) { return null }

    return DateNormalizer.normalize(date)
  }
}

export { DynamicPeriod }
