import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-switch"
export default class extends Controller {
  static targets = ['actual']

  static values = { current: String }

  connect() {
    this.announceChange()
  }

  currentValueChanged(value, previousValue) {
    this.actualTarget.value = this.currentValue

    if (previousValue != '') {
      // non-stiumulus event left until we make sure nothing is listening to it
      this.actualTarget.dispatchEvent(new Event('change'))
      this.announceChange()
    }
  }

  switch(event) {
    this.currentValue = event.params.val
  }

  announceChange() {
    this.dispatch('changed', { detail: { value: this.currentValue } })
  }
}
