import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import parseDate from "../date_utils/parse_date"

// Connects to data-controller="inline-picker"
export default class extends Controller {
  static targets = [
    'fpField',    // Flatpickr field
    'pickerArea'  // where the flatpickr will be attached
  ]

  static outlets = ['fp-area', 'date-field']

  static values = {
    initial: String,
    fieldType: { type: String, default: 'multi' }
  }

  connect() {
    this.init()
  }

  init() {
    if (!this.hasDateFieldOutlet) {
      console.error('No date field outlet');
    }

    if (this.hasInitialValue) {
      this.setFieldFromInitial()
    }

    this.initFpArea()
  }

  initFpArea() {
    this.picker = flatpickr(this.fpFieldTarget, this.pickerConfig)
    // update summary via outlet
  }

  /**
   * Handle when the flatpickr has been changed. Passed as a config option to
   * the flatpickr instance.
   * @param {array} selectedDates
   * @param {string} dateStr
   * @param {object} instance
   */
  pickerChanged(selectedDates, dateStr, instance) {
    this.dateField.setByJS(selectedDates[0])

    // update summary via outlet
  }


  /**
   * Handle when a text field input has changed. Validation should be mostly
   * handled by the date field.
   * @param {event} event
   * @todo Decorate the field when there's an error
   */
  fieldChanged(event) {
    this.setPickerFromField()
  }


  /**
   * When the user clears the input field, clear the flatpickr (only)
   * @param {event} event
   */
  fieldCleared(event) {
    this.picker.setDate(null)
  }


  /**
   * Allows for programmatically clearing both the field and the picker
   * @param {event} event
   */
  clear(event) {
    this.dateField.clear()
    this.picker.setDate(null)
  }


  setFieldFromInitial() {
    this.date = parseDate(this.initialValue)
    this.dateField.setByDateTime(this.date)
  }

  /**
   * @param {Date} date
   */
  setFieldWithDate(date) {
    // this.dateField.setByJS(date)
    this.setField(DateTime.fromJSDate(date))
  }

  /**
   * @param {DateTime} dt
   */
  setField(dt) {
    this.dateField.setByDateTime(dt)
  }


  /**
   * Read what is currently in the input fields and update flatpickr accordingly.
   * It doesn't seem like we need to check if the dateField is valid, because
   * the flatpickr will just ignore invalid dates.
   */
  setPickerFromField() {
    this.picker?.setDate(this.dateField.toJSDate, false)
  }


  /**
   * @param {Date} date
   */
  setDate(date) {
    this.date = date
    this.setFieldWithDate(date)
    this.setPickerFromField()
  }

  get dateField() {
    return this.dateFieldOutlet
  }

  /**
   * The flatpickr config
   * - inline makes the picker always visible
   * - appendTo allows us to choose where the picker is placed in the DOM
   * - onChange allows us to do something when a range is chosen in the picker
   * @returns {object}
   */
  get pickerConfig() {
    return {
      altInput: true,
      inline: true,
      altFormat: "m/d/Y",
      dateFormat: "Y-m-d",
      mode: 'single',
      defaultDate: this.dateField.toJSDate,
      appendTo: this.pickerAreaTarget,
      onChange: this.pickerChanged.bind(this)
    }
  }
}
