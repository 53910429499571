import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pop-manager"
export default class extends Controller {

  static targets = [
    'recentPeriod',
    'priorPeriod',
  ]

  static outlets = [
    'recentPeriodManager',
    'priorPeriodManager',
  ]

  connect() {}

  get dataId() {
    return this.element.id || 'pop-manager'
  }

  update(event) {
    let params = event.params

    if (params?.position == 'recent') {
      this.handleChangeFromRecent()
    }
  }

  pickerChanged(position) {
    if (position == 'recent') { this.handleChangeFromRecent() }
  }

  handleChangeFromRecent() {
    this.priorPeriodManager.handleChangeFromLeader(this.recentData)
  }

  /**
   * Handles change from the tab switcher
   * @param {Event} event
   */
  updateMode(event) {}

  get recentPeriodManager() {
    return this.application.getControllerForElementAndIdentifier(this.recentPeriodTarget, "period-manager")
  }

  get priorPeriodManager() {
    return this.application.getControllerForElementAndIdentifier(this.priorPeriodTarget, "period-manager")
  }

  get recentData() {
    return this.recentPeriodManager.memo
  }

  get priorData() {
    return this.priorPeriodManager.memo
  }
}
