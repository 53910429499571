import { prefix } from '@fortawesome/pro-light-svg-icons'
import DateNormalizer from './normalizer'
import { DateTime } from 'luxon'

export default class AbsoluteSummarizer {
  static summarize(minDate, maxDate) {
    return new AbsoluteSummarizer(minDate, maxDate)
  }

  /**
   * @param {*} dateMin
   * @param {*} dateMax
   * @param {Boolean} showComp
   * @returns {AbsoluteSummarizer}
   */
  constructor(dateMin = null, dateMax = null) {
    this.dateMin = new DateNormalizer(dateMin).toDateTime()
    this.dateMax = new DateNormalizer(dateMax).toDateTime()
  }

  summary() {
    if (!this.isValid) { return "" }

    let str

    if (this.dateMin && this.dateMax) {
      let arr = [this.formattedDateMin, this.formattedDateMax]
      str = arr.join(this.infix)
    } else if (this.dateMax) {
      str = `${this.prefix} ${this.formattedDateMax}`
    } else {
      str = `${this.prefix} ${this.formattedDateMin}`
    }

    return str
  }

  get summaryData() {
    return {
      dateMinStr: this.formattedDateMin,
      dateMaxStr: this.formattedDateMax,
      prefix: this.prefix,
      infix: this.infix,
      summaryStr: this.summary(),
      formattedRange: [this.formattedDateMin, this.formattedDateMax],
      valid: this.isValid
    }
  }

  get infix() {
    if (this.dateMin && this.dateMax) {
      return ' to '
    } else {
      return ''
    }
  }

  get prefix() {
    if (this.dateMax && !this.dateMin) {
      return "On or before"
    } else if (this.dateMin && !this.dateMax) {
      return "On or after"
    } else {
      return ""
    }
  }

  get formattedDateMin() {
    return this.dateMin?.toLocaleString(DateTime.DATE_SHORT) || ""
  }

  get formattedDateMax() {
    return this.dateMax?.toLocaleString(DateTime.DATE_SHORT) || ""
  }

  get isValid() {
    return (Boolean(this.dateMin) || Boolean(this.dateMax))
      && (this.dateMin?.isValid || this.dateMax?.isValid)
  }
}
