import { Controller } from "@hotwired/stimulus"
import { PolyPeriod } from '../periods/poly_period'

// Connects to data-controller="dynamic-picker"
export default class extends Controller {
  static targets = [ 'summary', 'option' ]

  static outlets = ['period-manager', 'pop-manager']

  // static values = { showComparison: { type: Boolean, default: false } }
  static values = {
    position: String
  }

  connect() {
    this.dataId = this.element.id || app.randomString(8)

    if (this.hasPeriodManagerOutlet) {
      // query the leader; when this connects everything else should be loaded
      this.periodManagerOutlet.register('dynamic', this)
      this.updateManager()
    }

    if (this.isFollower && this.hasPopManagerOutlet) {
      this.followTheLeader(this.popManagerOutlet.recentData)
    }
  }

  handleChange(event) {
    if (this.hasPeriodManagerOutlet) {
      this.updateOrSetErrorOnManager()
    }
  }

  updateManager() {
    this.periodManagerOutlet.update('dynamic', this.summaryData)
  }

  updateOrSetErrorOnManager() {
    if (!this.isFollower || !this.isFollowing || (this.isFollowing && this.leader?.isFollowable)) {
      this.updateManager()
      return
    }

    if (this.isFollowing && !this.leader?.isFollowable) {
      this.setUnfollowableLeaderError()
      return
    }
  }

  get checkedOption() {
    return this.optionTargets.filter((option) => option.checked)[0]
  }

  get periodName() {
    return this.checkedOption?.value
  }

  set periodName(value) {
    let current = this.periodName
    this.optionTargets.forEach((option) => option.checked = (option.value === value))

    if (current !== value) { this.handleChange() }
  }

  get summaryStr() {
    return document.querySelector(`[data-summary-for="${this.periodName}"]`)?.textContent?.trim()
  }

  get summaryData() {
    return Object.assign({
      summaryGroup: 'dynamic',
      summaryStr: this.summaryStr,
      period: this.periodName,
      valid: Boolean(this.summaryStr?.length)
    }, this.periodModel.summaryData)
  }

  get periodModel() {
    return new PolyPeriod({
      dateMode: 'dynamic',
      prefix: this.periodManagerOutlet.periodValue,
      dynamicPeriod: this.periodName,
      leader: this.leader
    })
  }

  // get conversions() {
  //   return this.periodModel.conversions
  // }

  announceChanged() {
    this.dispatch('changed', { detail: this.summaryData })
  }

  /**
   * Set new leader data and update the PeriodManager
   * @param {Object} recentMemo 'memo' data from recent PeriodManager
   */
  followTheLeader(recentMemo) {
    recentMemo = recentMemo || {}

    // Trying to follow on missing data can cause errors
    if (!this.memoIsUsable(recentMemo)) { return }

    // sets the leader which will be used in periodModel()
    this.leader = new PolyPeriod({
      dateMode:      recentMemo.dateMode,
      dateMin:       recentMemo.absolute?.dateMin,
      dateMax:       recentMemo.absolute?.dateMax,
      lookbackMin:   recentMemo.relative?.lookbackMin,
      lookbackMax:   recentMemo.relative?.lookbackMax,
      dynamicPeriod: recentMemo.dynamic?.period,
    })

    if (this.leader.isFollowable) {
      this.updateManager()
    } else {
      this.setUnfollowableLeaderError()
    }
  }

  get isFollower() {
    return this.positionValue === 'prior'
  }

  get periodIsFollowing() {
    return ['prior_period', 'prior_year'].includes(this.periodName)
  }

  get isFollowing() {
    return this.isFollower && this.periodIsFollowing
  }

  setUnfollowableLeaderError() {
    this.periodManagerOutlet.purgeSlots()
    this.periodManagerOutlet.setError(this.unfollowableLeaderMsg)
  }

  get unfollowableLeaderMsg() {
    return `
      Recent period must have start and end dates to use
      ${this.periodName.replace('_', ' ')}
    `
  }

  // Does the memo have the data for the mode, and is it good?
  memoIsUsable(memo) {
    return Boolean(memo[memo.dateMode]) && memo[memo.dateMode].valid
  }
}
