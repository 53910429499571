import { Controller } from "@hotwired/stimulus"
import parseDate from "../date_utils/parse_date"
import { DateTime } from "luxon"
import flatpickr from "flatpickr"
import AbsoluteSummarizer from "../date_utils/absolute_summarizer"
import { useDebounce } from "stimulus-use"

// Connects to data-controller="absolute-picker"
export default class extends Controller {
  static targets = [
    'fpField',    // Flatpickr field
    'pickerArea'  // where the flatpickr will be attached
  ]

  static outlets = ['date-field', 'period-manager', 'pop-manager']

  static values = {
    initial: Array,
    position: String
  }

  static debounces = ['handleChange']

  connect() {
    useDebounce(this)
    this.type = 'absolute'
    this.init()
  }

  init() {
    if (this.hasInitialValue) {
      this.setFieldsFromInitial()
    }

    // this.initRange()
    this.initFpArea()

    if (this.hasPeriodManagerOutlet) {
      this.updateManager()
    }

    this.handleChange('init')
  }

  /**
   * Set the range from what we have available or default it
   */
  initRange() {
    if (this.hasInitialValue) {
      this.range = this.initialValue.map(date => parseDate(date))
    } else if (this.hasDateFieldOutlet) {
      this.range = this.fieldDateTimes
    } else {
      this.range = [DateTime.local().minus({ days: 30 }), DateTime.local()]
    }
  }

  initFpArea() {
    this.picker = flatpickr(this.fpFieldTarget, this.pickerConfig)
  }

  /**
   * Handle when the flatpickr has been changed. Passed as a config option to
   * the flatpickr instance.
   * @param {array} selectedDates
   * @param {string} dateStr
   * @param {object} instance
   */
  pickerChanged(selectedDates, dateStr, instance) {
    this.setFieldsWithDates(selectedDates)
    this.handleChange('pickerChanged')
  }

  /**
   * Handle when a text field input has changed. If there is input but the date
   * is invalid, raise an error. Otherwise assume the field has a good date or
   * has been cleared. Empty fields should be handled in other methods.
   * @param {event} event
   * @todo Decorate the field when there's an error
   */
  fieldChanged(event) {
    this.setPickerFromFields()
    this.handleChange('fieldChanged')
  }

  /**
   * When the user clears the input field, clear the flatpickr
   * @param {event} event
   */
  fieldCleared(event) {
    this.picker.setDate(null)
    this.handleChange('fieldCleared')
  }


  /**
   * Allows for programmatically clearing both the field and the picker
   * @param {event} event
   */
  clear(event) {
    this.dateMinField.clear()
    this.dateMaxField.clear()
    this.picker.setDate(null)
  }


  /**
   * Moves the picker to a specific date
   * Call externally (from DateField#focus)
   * @param {Date} date
   */
  jumpToDate(date) {
    this.picker.jumpToDate(date)
  }

  setFieldsFromInitial() {
    const dates = this.initialValue.map(date => parseDate(date))
    this.setFields(dates)
  }

  /**
   *
   * @param {Date[]} dates
   */
  setFieldsWithDates(dates) {
    const rng = dates.map(d => DateTime.fromJSDate(d))
    this.setFields(rng)
    this.range = rng
  }

  /**
   * Set the dateFields to the given dates
   * @param {DateTime[]} dates
   */
  setFields(dts) {
    this.dateMinField.setByDateTime(dts[0])

    if (dts.length > 1) {
      this.dateMaxField.setByDateTime(dts[1])
    }
  }

  setFieldsFromRange() {
    this.setFields(this.range)
  }


  // This can be called early, before the picker is initialized
  setPickerFromFields() {
    if (this.fieldsValid && this.picker) {
      this.picker.setDate(this.fieldDates)
      this.range = this.fieldDateTimes
    }
  }


  /**
   * Set the internal range
   * @param {DateTime} startDt
   * @param {DateTime} endDt
   */
  // setRange(startDt, endDt) {
  //   this.range = [startDt, endDt]
  // }

  /**
   * Set everything to a new range
   * @param {Date} startDate
   * @param {Date} endDate
   */
  setTo(startDate, endDate) {
    this.range = [startDate, endDate].map(date => DateTime.fromJSDate(date))
    this.setFieldsFromRange()
    this.picker?.setDate([startDate, endDate], false)
    this.handleChange('setTo')
  }


  get dateMinField() {
    return this.dateFieldOutlets.find(field => field.extent == 'min')
  }

  get dateMaxField() {
    return this.dateFieldOutlets.find(field => field.extent == 'max')
  }

  get fieldDateTimes() {
    return this.dateFieldOutlets.map(field => field.datetime)
  }

  get fieldDates() {
    return this.fieldDateTimes.map(dt => dt?.toJSDate())
  }

  get fieldsValid() {
    return this.dateFieldOutlets.every(field => field.isValid)
  }

  handleChange(cause = null) {
    this.announceChange(cause)
    this.updateManagers()
  }

  announceChange(cause = null) {
    this.dispatch('changed', { detail: Object.assign(this.summaryData, { cause: cause }) })
  }

  updateManagers() {
    this.updatePeriodManagerData()
    this.updatePopManager()
  }

  updatePeriodManagerData() {
    if (this.hasPeriodManagerOutlet) {
      this.periodManagerOutlet.update(this.type, this.summaryData)
    }
  }

  updateManager() { this.updatePeriodManagerData() }

  updatePopManager() {
    if (this.hasPopManagerOutlet) {
      this.popManagerOutlet.pickerChanged(this.positionValue)
    }
  }

  /**
   * Collects useful data from multiple sources.
   * When the date fields are removed from the DOM they trigger a change event
   * therefore we can't expect them to be always available.
   *
   * @returns {object}
   */
  get summaryData() {
    return Object.assign(
      {
        summaryGroup: this.type,
        range: this.fieldDateTimes,
        isoRange: this.fieldDateTimes.filter(Boolean).map(dt => dt.toISODate()),
        dateMin: this.dateMinField?.datetime,
        dateMax: this.dateMaxField?.datetime
      },
      this.summarizer.summaryData
    )
  }

  get summarizer() {
    return new AbsoluteSummarizer(this.fieldDateTimes[0], this.fieldDateTimes[1])
  }


  /**
   * The flatpickr config
   * - inline makes the picker always visible
   * - appendTo allows us to choose where the picker is placed in the DOM
   * - onChange allows us to do something when a range is chosen in the picker
   * @returns {object}
   */
  get pickerConfig() {
    return {
      altInput: true,
      inline: true,
      altFormat: "m/d/Y",
      dateFormat: "Y-m-d",
      mode: 'range',
      defaultDate: this.fieldDates,
      appendTo: this.pickerAreaTarget,
      onChange: this.pickerChanged.bind(this)
    }
  }
}
