import { DateTime } from 'luxon'

/**
 * Parse a US date string. We need this before flatpickr is initialized, so
 * it cannot use the built in parser.
 * @param {string} date - a US date string: mm/dd/YYYY
 * @returns {DateTime} a JS Date object
 */
export default function parseDate(date) {
  if (date === "") { return null }

  return DateTime.fromFormat(date, 'D')
}
