import {
  DayStrategy,
  MonthStrategy,
  MtdStrategy,
  QtdStrategy,
  TrailingMonthStrategy,
  WeekStrategy,
  YtdStrategy
} from './all'

export default class PeriodData {
  constructor(data) {
    this.data = data
  }

  find(period) {
    return this.data[period]
  }

  offsetFor(period) {
    return this.find(period)?.offset
  }

  unitFor(period) {
    if (period == 'custom') {
      return 'day'
    } else {
      return this.find(period)?.unit
    }
  }

  strategyFor(period) {
    if (period == 'custom') {
      return new DayStrategy()
    } else {
      let strategy = this.find(period)['strategy'] || 'day'
      return new this.strategies[strategy]()
    }
  }

  /**
   *
   * @param {String} period
   * @param {DateTime} fromDate
   * @returns {*} a kind of Strategy
   */
  build(period, fromDate) {
    let strategy = this.strategyFor(period)
    strategy.fromDate = fromDate
    strategy.period = period

    return strategy
  }

  get customStrategy() {
    return DayStrategy
  }

  get strategies() {
    return {
      day: DayStrategy,
      month: MonthStrategy,
      mtd: MtdStrategy,
      qtd: QtdStrategy,
      trailing_month: TrailingMonthStrategy,
      week: WeekStrategy,
      ytd: YtdStrategy
    }
  }
}

export { PeriodData }
