import { Controller } from "@hotwired/stimulus"
import { DateTime } from 'luxon'

// Connects to data-controller="date-field"
export default class extends Controller {
  static targets = ['day', 'month', 'year', 'ui']

  static values = {
    initial: String,
    extent: String
  }

  static classes = ['fieldError', 'textError']

  static outlets = ['absolute-picker']

  connect() {
    if (this.hasInitialValue) {
      this.datetime = this.parseDate(this.initialValue)
      this.setFieldsFromDate()
    } else if (this.hasContent) {
      this.setDateFromFields()
      this.format()
    }
  }

  focus(event) {
    if (this.hasAbsolutePickerOutlet) {
      this.absolutePickerOutlet.jumpToDate(this.toJSDate)
    }
  }

  blur(event) {
    if (this.hasContent) { this.validateAll() }
    if (this.isValid) { this.format() }
  }

  update(event) {
    this.validateAll()
    if (this.isValid) { this.setDateFromFields() }
    this.format()
    this.announceChange()
  }

  clear(event) {
    this.dayTarget.value = ""
    this.monthTarget.value = ""
    this.yearTarget.value = ""
    this.datetime = null
    this.removeFieldError()

    this.dispatch('cleared')
  }

  validateAll() {
    this.validateDay()
    this.validateMonth()
    this.validateYear()
    this.validateWhole()
  }

  validateDay() {
    if (this.day.length && this.dayOutOfRange) {
      this.dayTarget.classList.add(...this.textErrorClasses)
    } else {
      this.dayTarget.classList.remove(...this.textErrorClasses)
    }
  }

  validateMonth() {
    if (this.month.length && (this.month < 1 || this.month > 12)) {
      this.monthTarget.classList.add(...this.textErrorClasses)
    } else {
      this.monthTarget.classList.remove(...this.textErrorClasses)
    }
  }

  validateYear() {
    if (this.year.length != 4) {
      this.yearTarget.classList.add(...this.textErrorClasses)
    } else {
      this.yearTarget.classList.remove(...this.textErrorClasses)
    }
  }

  validateWhole() {
    if (this.isValid) {
      this.removeFieldError()
    } else {
      this.setFieldError()
    }
  }

  setFieldError() {
    this.element.classList.add(...this.fieldErrorClasses)
  }

  removeFieldError() {
    this.element.classList.remove(...this.fieldErrorClasses)
  }

  get day() {
    return this.dayTarget.value
  }

  get dayOutOfRange() {
    if (this.hasAllContent) {
      let dim = DateTime.fromObject({ month: this.month, year: this.year }).daysInMonth
      return this.day < 1 || this.day > 31 || this.day > dim
    }
  }

  get month() {
    return this.monthTarget.value
  }

  get year() {
    return this.yearTarget.value
  }

  get isValid() {
    if (this.day.length && this.month.length && this.year.length) {
      let dt = DateTime.fromObject({
        day: this.day,
        month: this.month,
        year: this.year
      })

      return dt.isValid && this.year.length == 4
    } else {
      return false
    }
  }

  get hasContent() {
    return this.dayTarget.value || this.monthTarget.value || this.yearTarget.value
  }

  get hasAllContent() {
    return this.fields.every(field => field.value.length)
  }

  get fields() {
    return [this.dayTarget, this.monthTarget, this.yearTarget]
  }

  /**
   * Used when part of a range, from data-range-extent
   */
  get extent() {
    return this.element.dataset.rangeExtent
  }


  /*
    setDateFromFields and setFieldsFromDate can be invoked from connect(), which
    is too early to announceChange for picker controllers because the picker is not
    intiialized yet.
  */

  setDateFromFields() {
    this.datetime = DateTime.fromObject({
      day: this.day,
      month: this.month,
      year: this.year
    })
  }

  setFieldsFromDate() {
    if (this.datetime) {
      this.dayTarget.value   = String(this.datetime.day).padStart(2, '0')
      this.monthTarget.value = String(this.datetime.month).padStart(2, '0')
      this.yearTarget.value  = this.datetime.year
    }
  }

  setByString(str) {
    this.datetime = this.parseDate(str)
    this.setFieldsFromDate()
    this.announceChange()
  }

  setByJS(date) {
    this.datetime = DateTime.fromJSDate(date)
    this.setFieldsFromDate()
    this.announceChange()
  }

  setByDateTime(dt) {
    this.datetime = dt
    this.setFieldsFromDate()
    this.announceChange()
  }

  get toJSDate() {
    return this.datetime?.toJSDate()
  }

  // format day and month to two digits
  format() {
    if (this.day.length) {
      this.dayTarget.value = this.dayTarget.value.padStart(2, '0')
    }

    if (this.month.length) {
      this.monthTarget.value = this.monthTarget.value.padStart(2, '0')
    }
  }

  /**
   * Parse a US date string. We need this before flatpickr is initialized, so
   * it cannot use the built in parser.
   * @param {string} date - a US date string: mm/dd/YYYY
   * @returns {date} a JS Date object
   */
  parseDate(date) {
    if (date === "") { return null }

    return DateTime.fromFormat(date, 'D')
  }

  announceChange() {
    this.dispatch('changed', { detail: { field: this, datetime: this.datetime, date: this.toJSDate } })
  }

  announceCleared() {
    this.dispatch('cleared', { detail: { field: this } })
  }
}
