import { Controller } from "@hotwired/stimulus"
import { DateNormalizer } from "../date_utils/normalizer"

// Connects to data-controller="range-preset"
export default class extends Controller {
  static values = {
    range: Array
  }

  static outlets = [ 'absolute-picker', 'date-field' ]

  connect() {
    this.periodData = app.periodData
    this.range
  }

  /**
   * Handle when the user clicks a preset
   * @param {event} event
   */
  setPreset(event) {
    event.preventDefault()

    this.range = this.rangeFor(event.params.preset)

    if (this.hasDateFieldOutlet) {
      this.setFields()
    } else if (this.hasAbsolutePickerOutlet) {
      this.setPicker()
    }
  }

  setFields() {
    this.dateMinField.setByJS(this.range[0])
    this.dateMaxField.setByJS(this.range[1])
  }

  setPicker() {
    this.absolutePickerOutlet.setTo(...this.range)
  }

  /**
   *
   * @param {String} preset
   * @returns Date[]
   */
  rangeFor(preset) {
    return this.periodData.build(preset, this.fromDate).dateRange
  }

  /**
   * @returns {Date}
   */
  get fromDate() {
    if (this.hasRangeValue) {
      return new DateNormalizer(this.rangeValue[1]).toJSDate()
    } else {
      return new Date()
    }
  }

  get dateMinField() {
    return this.dateFieldOutlets.find(field => field.extent == 'min')
  }

  get dateMaxField() {
    return this.dateFieldOutlets.find(field => field.extent == 'max')
  }
}
