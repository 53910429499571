import { DateNormalizer } from '../date_utils/normalizer'
import { DateTime } from 'luxon'

import { AbsolutePeriod } from './absolute_period'
import { RelativePeriod } from './relative_period'
import { DynamicPeriod } from './dynamic_period'

import DayStrategy from './day_strategy'

export default class PolyPeriod {
  constructor(options) {
    this.dateMode    = options.dateMode
    this.dateMin     = this.normalize(options.dateMin)
    this.dateMax     = this.normalize(options.dateMax)
    this.lookbackMin = options.lookbackMin
    this.lookbackMax = options.lookbackMax
    this.anchorDate  = this.normalize(options.anchorDate)
    this.dynamicPeriod = options.dynamicPeriod
    this.prefix = options.prefix
    this.leader = options.leader

    this.initAbsolute()
    this.initRelative()
    this.initDynamic()
  }

  initAbsolute() {
    this.absolute = new AbsolutePeriod({
      dateMin: this.dateMin,
      dateMax: this.dateMax,
      anchorDate: this.anchorDate,
    })
  }

  initRelative() {
    this.relative = new RelativePeriod({
      lookbackMax: this.lookbackMax,
      lookbackMin: this.lookbackMin,
      anchorDate: this.anchorDate,
    })
  }

  initDynamic() {
    this.dynamic = new DynamicPeriod({
      periodName: this.dynamicPeriod,
      anchorDate: this.anchorDate,
      leader: this.leader,
    })
  }

  get unifiedDateRange() {
    if (this.isRelative) {
      return this.relativeToAbsolute
    } else if (this.isDynamic) {
      return this.dynamicToAbsolute
    } else {
      return this.absoluteDateRange
    }
  }

  get dateModes() { return ['absolute', 'relative', 'dynamic'] }

  get dateModeIndex() {
    return this.dateModes.indexOf(this.dateMode)
  }

  get isAbsolute() { return this.dateMode === 'absolute' }

  get isRelative() { return this.dateMode === 'relative' }

  get isDynamic() { return this.dateMode === 'dynamic' }

  get isFollower() { return Boolean(this.leader) }

  /**
   * Returns an object with date properties for building a summary
   * @returns {object}
   */
  get summaryData() {
    return {
      dateMode: this.dateMode,
      dateRange: this.unifiedDateRange,
      prefix: this.prefix,
      absoluteDateRange: this.absoluteDateRange,
      relativeDateRange: this.relativeDateRange,
      dynamicDateRange: this.dynamicDateRange,
      absoluteToRelative: this.absoluteToRelative,
      relativeToAbsolute: this.relativeToAbsolute,
      dynamicToAbsolute: this.dynamicToAbsolute,
      dynamicToAbsoluteStr: this.dynamicToAbsoluteStr,
      dynamicToRelative: this.dynamicToRelative,
      dynamicToRelativeStr: this.dynamicToRelativeStr,
      canFollow: this.canFollow,
      followable: this.isFollowable,
    }
  }


  // Absolute dates
  // ------------------------------------------------------------------------ //

  get absoluteDateRange() { return this.absolute.dateRange }

  set absoluteDateRange(dates) { this.absolute.dateRange = dates }

  get absoluteDatesProvided() { return this.absolute.datesProvided }

  get fullAbsoluteDateRangeProvided() { return this.absolute.fullDateRangeProvided }

  get absoluteToRelative() {
    if (this.isAbsolute) { return this.absolute.toRelative }
  }



  // Relative dates
  // ------------------------------------------------------------------------ //

  /**
   * Farthest -> Nearest
   * Max is the farthest date, Min is the nearest date
   * max: 30, min: 0 means 30 days ago to today
   */
  get relativeDateRange() { return this.relative.dayRange }

  get relativeToAbsolute() { return this.relative.toAbsolute }

  get relativeDatesProvided() { return this.relative.daysProvided }

  get fullRelativeDatesProvided() { return this.relative.fullDayRangeProvided }



  // Dynamic dates
  // ------------------------------------------------------------------------ //

  get dynamicDateRange() { return this.dynamic.dateRange }

  get strategyForPeriod() { return this.dynamic.strategyForPeriod }

  get priorPeriodStrategy() {
    return this.leader?.priorPeriod
  }

  get priorYearStrategy() {
    return this.leader?.priorYear
  }

  get dayStrategy() {
    let ds = new DayStrategy()
    // ds.fromDate = this.unifiedDateRange[1]
    ds.dateEnd = this.unifiedDateRange[1]
    ds.dateStart = this.unifiedDateRange[0]
    ds.customRange = true
    ds.period = 'custom'

    return ds
  }

  get dynamicDatesProvided() {
    return this.isDynamic && this.dynamic.datesProvided
  }

  get canFollow() { return this.isDynamic && this.dynamic.canFollow }

  get isFollowable() {
    return this.dynamicDatesProvided
      || this.fullAbsoluteDateRangeProvided
      || this.fullRelativeDatesProvided
  }

  // change the way period is compared based on mode
  get priorPeriod() {
    if (this.isDynamic) {
      return this.dynamic.priorPeriod
    } else {
      return this.dayStrategy.compare('period')
    }
  }

  get priorYear() {
    if (this.isDynamic) {
      return this.dynamic.priorYear
    } else {
      return this.dayStrategy.compare('year')
    }
  }

  get dynamicToAbsolute() { return this.dynamic.toAbsolute }

  get dynamicToAbsoluteStr() { return this.dynamic.toAbsoluteStr }

  get dynamicToRelative() { return this.dynamic.toRelative }

  get dynamicToRelativeStr() { return this.dynamic.toRelativeStr }

  // Utils
  // ------------------------------------------------------------------------ //

  get today() {
    // return this.normalize(new Date())
    return DateTime.local()
  }

  normalize(date) {
    if (!Boolean(date)) { return null }

    return DateNormalizer.normalize(date)
  }

  diffInDays(later, earlier) {
    return Math.trunc(later.diff(earlier, 'days').as('days'))
  }
}

export { PolyPeriod }
