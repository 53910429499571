import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="period-manager"
export default class extends Controller {
  static targets = [
    'summary',
    'dateMode'
  ]

  static outlets = [
    'pop-manager'
  ]

  static values = {
    period: String
  }

  connect() {
    this.memo = {}
    this.pickers = {}
    this.lastVisibleGroup

    if (this.hasDateModeTarget) {
      this.memo.dateMode = this.currentDateMode
    }

    this.dataId = this.element.id || `${this.periodValue}-manager-${app.randomString(4)}`

    app.periodManagers = app.periodManagers || {}
    app.periodManagers[this.dataId] = this
  }

  get isRecentManager() {
    return this.periodValue === 'recent'
  }

  get isPriorManager() {
    return this.periodValue === 'prior'
  }

  register(name, picker) {
    this.pickers[name] = picker
  }

  // Update the internal data from a picker
  update(name, data) {
    Object.assign(this.memo, { [name]: data })

    this.populateSlots()
  }

  /**
   * When the PopManager receives a change from the leader it will call this
   * @param {Object} recentMemo the data from the recent PeriodManager
   */
  handleChangeFromLeader(recentMemo) {
    if (this.isRecentManager) { return }

    // The PopManager needs to send the new data from the leader
    // populate slots with updated data
    if (this.pickers['dynamic']) {
      this.pickers.dynamic.followTheLeader(recentMemo)
    }
  }

  // Allow something like tab-switcher to set the visible summary
  //
  show(event) {
    if (event.params?.group) {
      this.memo.dateMode = event.params.group
      this.reveal(event.params.group)
    } else if (event.detail?.value) {
      this.memo.dateMode = event.detail.value
      this.reveal(event.detail.value)
    }
    // this.memo.dateMode = event.detail.value
    // this.reveal(event.detail.value)
  }

  reveal(groupName) {
    let grp = this.summaryGroups.find(group => group.getAttribute('data-tpl-group') === groupName)

    if (Boolean(grp)) {
      this.lastVisibleGroup = groupName
      this.hideSummaryGroups()
      grp.classList.remove('hidden')
    }
  }

  hideSummaryGroups() {
    this.summaryGroups.forEach(group => group.classList.add('hidden'))
  }

  // populateTemplate() {
  //   this.groupNames.forEach(name => {
  //     this.populateSegment(name, this.memo[name] || {});
  //   })
  // }

  // populateSegment(groupName, data) {
  //   const group = this.summaryTarget.querySelector(`[data-tpl-group="${groupName}"]`);
  //   const slots = group?.querySelectorAll('[data-tpl-slot]') || []

  //   slots.forEach(slot => {
  //     const key = slot.getAttribute('data-tpl-slot');
  //     if (data[key] !== undefined) {
  //       slot.textContent = data[key];
  //     }
  //   })
  // }

  populateSlots() {
    this.clearError()

    this.summarySlots.forEach(slot => {
      const src = slot.getAttribute('data-tpl-source')
      const key = slot.getAttribute('data-tpl-slot')

      if (this.memo[src]?.[key] !== undefined) {
        slot.textContent = this.memo[src][key]
      }
    })
  }

  purgeSlots() {
    this.summarySlots.forEach(slot => slot.textContent = '')
  }

  setError(msg) {
    this.hideSummaryGroups()

    if (this.errorGroup) {
      this.errorGroup.classList.remove('hidden')
    }

    if (this.errorSlot) {
      this.errorSlot.textContent = msg
    }
  }

  clearError() {
    this.errorGroup.classList.add('hidden')
    this.errorSlot.textContent = ''
    this.reveal(this.lastVisibleGroup)
  }

  get groupNames() {
    return this.summaryGroups.map(group => group.getAttribute('data-tpl-group'))
  }

  get summaryGroups() {
    return Array.from(this.summaryTarget.querySelectorAll('[data-tpl-group]'))
  }

  get summarySlots() {
    return Array.from(this.element.querySelectorAll('[data-tpl-slot]'))
  }

  get errorGroup() {
    return this.element.querySelector('[data-tpl-error]')
  }

  get errorSlot() {
    return this.errorGroup?.querySelector('[data-tpl-slot="error"]')
  }

  get currentDateMode() {
    return this.dateModeTarget.value
  }

  groupIsValid(groupName) {
    return this.memo[groupName]?.valid !== false
  }
}
