import merge from 'deepmerge'
import Legend from "../../maps/legend"
import MapController from "../map_controller"
import CampusForm from '../../maps/campus_form'
import Marker from '../../maps/popups/marker'
import LegendSkeleton from '../../maps/legend_skeleton'
import EmptyLegend from '../../maps/empty_legend'

export default class extends MapController {
  static targets = ['container', 'controlPanel', 'legend', 'campusSelect']
  static values = {
    lat: Number,
    lng: Number,
    zoom: Number,
    bounds: Array,
    locationsUrl: String
  }

  connect () {
    super.connect()
    this.legends = {}
    this.markers = []
    this.campusForm = new CampusForm(this.campusSelectTarget)

    this.map.on('load', () => {
      this.onion.addCensusBlocks(this.censusBlocksId)
      this.addPins(this.locationsUrlValue)
    })
  }

  moveToCampus () {
    if (this.campusForm.valid) {
      // Open campus popup
      this.map.once('moveend', () => {
        this.map.fire(
          'click',
          {
            latLng: this.campusForm.lngLat,
            point: this.map.project(this.campusForm.lngLat),
            originalEvent: {}
          }
        )
      })

      this.flyTo(
        this.campusForm.lng,
        this.campusForm.lat,
        12
      )
    } else {
      this.map.fitBounds(this.boundsValue)
    }
  }

  addMarker (evnt) {
    evnt.preventDefault()
    const center = this.map.getCenter()
    const marker = new Marker(center['lat'], center['lng']).addTo(this.map)
    this.markers.push(marker)
    marker.togglePopup()
  }

  addIso ({ detail: { id } }) {
    this.onion.addOrUpdateISO(id)
  }

  removeIso({ detail: { id } }) {
    this.onion.hideLayer(id)
  }

  clearAll (event) {
    event.preventDefault()
    this.onion.clearIso()
    this.markers.forEach(m => m.remove())
  }

  toggleChoro (event) {
    const selected = event.target.options[event.target.selectedIndex]
    const url = selected.value
    const layer = selected.dataset.layerId

    if (selected.value === 'none') {
      this.onion.hideChoropleth()
      this.hideLegend()
    } else {
      this.showLoading()
      this.addOrShowChoro(layer, url)
        .then(() => this.onion.hideChoropleth({except: layer}))
        .then(() => this.updateLegend(layer))
    }
  }

  addOrShowChoro (layer, url) {
    return this.onion.hasLayer(layer) ? this.onion.showLayer(layer) : this.addChoro(url)
  }

  addChoro (url) {
    return fetch(url)
      .then(response => this.handleResponse(response))
      .then(json => this.addStateAndLegend(json))
      .then(config => this.addChoroLayer(config))
      .then(config => this.paintChoro(config))
      .catch(error => console.error(error))
  }

  addStateAndLegend (json) {
    let config = json.config

    if (this.emptyData(json.data)) {
      this.showNoDataMsg(json.config.id)
      config['empty'] = true
    } else {
      this.addFeatureState(json.data)
      this.addLegend(json.config.id, json.legend)
    }
    return config
  }

  addChoroLayer (config) {
    this.onion.addChoroLayer(config.id, config.type)
    return config
  }

  paintChoro (config) {
    if (config['empty'] !== true) {
      this.onion.paintLayer(config.id, config.paint)
    }
    return config
  }

  addLegend (layerId, legendData) {
    const legend = new Legend(legendData)
    this.legends[layerId] = legend
  }

  showNoDataMsg (layerId) {
    this.legends[layerId] = new EmptyLegend()
  }

  showLoading () {
    this.legendTarget.innerHTML = new LegendSkeleton().html()
  }

  updateLegend(layer) {
    this.legendTarget.innerHTML = this.legends[layer].html()
    this.legendTarget.classList.remove('hidden')
  }

  hideLegend () {
    this.legendTarget.classList.add('hidden')
  }

  addFeatureState (data) {
    data.forEach((obj) => {
      let id = obj.geo12_id
      delete obj.geo12_id
      let updatedState = merge(this.currentFeatureState(id), obj)

      this.map.setFeatureState(
        {
          source: this.censusBlocksId,
          sourceLayer: this.bgSourceLayer,
          id: id
        },
        updatedState
      )
    })
  }

  currentFeatureState (id) {
    return this.map.getFeatureState({
      id: id,
      source: this.censusBlocksId,
      sourceLayer: this.bgSourceLayer
    })
  }

  emptyData (data) {
    return Object.keys(data).length === 0
  }

  get censusBlocksId () {
    return 'census_blocks'
  }

  get bgSourceLayer () {
    return this.onion.bgSourceLayer
  }
}
