export default class RelativeSummarizer {
  /**
   * Farthest -> Nearest
   * Max is the farthest date
   * Min is the nearest date
   */
  constructor({ min, max }) {
    this.min = min
    this.max = max
  }

  get summaryData() {
    return {
      summaryStr: this.summary(),
      prefix: this.prefix(),
      infix: this.infix(),
      suffix: this.suffix(),
      valid: this.isValid,
      givenValues: { min: this.min, max: this.max }
    }
  }

  summary() {
    if (!this.isValid) { return "" }

    let str = this.prefix()

    if (Boolean(this.min)) {
      str += this.min
    }

    str += this.infix()

    if (Boolean(this.max)) {
      str += this.max
    }

    str += this.suffix()

    return str
  }

  prefix() {
    if (this.rangeIsFull) {
      return 'Between '
    } else if (this.rangeEndsToday) {
      return 'In the last '
    } else if (this.rangeIsBeginless) {
      return 'At least '
    } else if (this.rangeIsEndless) {
      return 'Up to '
    }
  }

  infix() {
    if (this.rangeIsFull) {
      return ' and '
    } else {
      return ''
    }
  }

  suffix() {
    if (this.rangeEndsToday) {
      return ' days'
    } else {
      return ' days ago'
    }
  }

  get daysAreEqual() {
    return this.max === this.min
  }

  get daysAreZero() {
    return this.max === 0 && this.min === 0
  }

  get rangeIsFull() {
    return Boolean(this.min) && Boolean(this.max)
  }

  get rangeEndsToday() {
    return Boolean(this.max) && this.min == 0
  }

  get rangeIsBeginless() {
    return Boolean(this.min) && !Boolean(this.max)
  }

  get rangeIsEndless() {
    return !Boolean(this.min) && Boolean(this.max)
  }

  get isValid() {
    return !this.daysAreZero && this.validDays && !this.daysAreBackwards
  }

  get validDays() {
    return (this.min >= 0) || this.max >= 0
  }

  get daysAreBackwards() {
    return this.max < this.min
  }
}
