import { Controller } from "@hotwired/stimulus"
import { DateTime } from 'luxon'

// Connects to data-controller="range-summary"
export default class extends Controller {
  static targets = [ "first", "middle", "last" ]

  static values = {
    minDate: String,
    maxDate: String
  }

  // static outlets = ['date-field']

  connect() {
    if (this.hasMinDateValue || this.hasMaxDateValue) {
      this.update()
    }
  }

  /**
   *
   * @param {String} start
   * @param {String} end
   */
  setRange(start, end) {
    this.minDateValue = start
    this.maxDateValue = end
    this.update()
  }

  update() {
    if (this.minDateValue && this.maxDateValue) {
      this.showFirst()
      this.firstTarget.textContent  = this.minDate.toFormat('D')
      this.middleTarget.textContent = this.text
      this.lastTarget.textContent   = this.maxDate.toFormat('D')
    } else if (this.maxDateValue) {
      this.hideFirst()
      this.middleTarget.textContent = this.text
      this.lastTarget.textContent   = this.maxDate.toFormat('D')
    } else if (this.minDateValue) {
      this.hideFirst()
      this.middleTarget.textContent = this.text
      this.lastTarget.textContent   = this.minDate.toFormat('D')
    } else {
      this.firstTarget.textContent  = ''
      this.middleTarget.textContent = ''
      this.lastTarget.textContent   = ''
    }
  }

  clear() {
    this.minDateValue = undefined
    this.maxDateValue = undefined
    this.update()
  }

  showFirst() {
    this.firstTarget.classList.remove('hidden')
  }

  hideFirst() {
    this.firstTarget.classList.add('hidden')
  }

  get minDate() {
    return DateTime.fromISO(this.minDateValue)
  }

  get maxDate() {
    return DateTime.fromISO(this.maxDateValue)
  }

  get text() {
    if (this.minDateValue && this.maxDateValue) {
      return 'to'
    } else if (this.maxDateValue) {
      return 'On or before'
    } else {
      return 'On or after'
    }
  }
}
