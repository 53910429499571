export default class RangeSummarizer {
  static summarize(minDate, maxDate) {
    return new RangeSummarizer(minDate, maxDate)
  }

  constructor(startDate = null, endDate = null, showComp = false) {
    this.startDate = startDate
    this.endDate = endDate
    this.showComp = showComp
  }

  summary() {
    let str

    if (this.startDate && this.endDate) {
      let arr = Array.from(new Set([this.formattedStartDate, this.formattedEndDate]))
      str = arr.join(' to ')
    } else if (this.endDate) {
      str = `On or before ${this.formattedEndDate}`
    } else {
      str = `On or after ${this.formattedStartDate}`
    }

    if (this.showComp) {
      str += `<div class="text-xs text-slate-400">
                compared to ${this.compSummary}
              </div>`
    }

    return str
  }

  get formattedStartDate() {
    return this.formatDate(this.startDate)
  }

  get formattedEndDate() {
    return this.formatDate(this.endDate)
  }

  formatDate(date) {
    if (!Boolean(date)) { return '' }

    const day = date.getDate()
    const month = date.getMonth() + 1 // getMonth() returns months from 0-11
    const year = date.getFullYear()

    return `${month}/${day}/${year}`
  }

  duplicateDate(originalDate) {
    return new Date(originalDate.getTime())
  }

  dayDiff(date1, date2) {
    const date1Ms = new Date(date1).getTime()
    const date2Ms = new Date(date2).getTime()
    const differenceMs = Math.abs(date2Ms - date1Ms)

    return Math.round(differenceMs / (1000 * 60 * 60 * 24))
  }

  get priorPeriod() {
    if (!Boolean(this.startDate) || !Boolean(this.endDate)) { return [] }

    let dayDiff = this.dayDiff(this.startDate, this.endDate)
    let start = this.duplicateDate(this.startDate)
    let end = this.duplicateDate(this.endDate)

    dayDiff++  // We don't want the ranges to overlap

    start.setDate(this.startDate.getDate() - dayDiff)
    end.setDate(this.endDate.getDate() - dayDiff)

    return [ start, end ]
  }

  get compSummary() {
    if (this.startDate && this.endDate) {
      return `${this.formatDate(this.priorPeriod[0])} to ${this.formatDate(this.priorPeriod[1])}`
    } else {
      return 'You must select an an end date'
    }
  }
}
